import p1 from "../../assests/12.jpg";
import p2 from "../../assests/13pg.jpg";
import p3 from "../../assests/13p.jpg";



const ProData = [
    {
        name:"iPhone 12",
        image:p1,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    },
    {
        name:"iPhone 12",
        image:p2,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    },
    {
        name:"iPhone 12",
        image:p3,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    },
    {
        name:"iPhone 12",
        image:p2,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    }
    ,
    {
        name:"iPhone 12",
        image:p3,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    },
    {
        name:"iPhone 12",
        image:p1,
        color:"space gray",
        storage:"256GB",
        price:"180,000LKR"
    }
    

]

export default ProData;