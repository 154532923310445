
import w1 from "../../assests/w1.png";
import w2 from "../../assests/w2.png";

const Watch = [
    {
        name:"Apple watch 2",
        image:w2,
        price:"18,00LKR"
    },
    {
        name:"Apple watch 2",
        image:w1,
        price:"10,000LKR"
    },
    {
        name:"Apple watch 2",
        image:w1,
        price:"11,000LKR"
    },
    {
        name:"Apple watch 2",
        image:w2,
        price:"10,000LKR"
    }
    ,
    {
        name:"Apple watch 2",
        image:w1,
        price:"20,000LKR"
    },{
        name:"Apple watch 2",
        image:w1,
        price:"11,000LKR"
    },
    {
        name:"Apple watch 2",
        image:w2,
        price:"10,000LKR"
    }
    ,
    {
        name:"Apple watch 2",
        image:w1,
        price:"20,000LKR"
    }

]

export default Watch;